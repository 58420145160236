<template>
  <div>
    <div>
      <div class="box1">
        <div
            style="
            position: absolute;
            width: 45px;
            height: 45px;
          "
            @click="slip()"
        >
          <img loading="lazy" src="@/assets/slipleft.png" />
        </div>

        <div class="ViewTitle">账户安全</div>
        <div class="list">
          <div @click="toPhone1()" :class="[!userInfo.gptsUserPhone ? 'error' : '', 'item']">
            <div class="icon">
              <img loading="lazy" src="@/assets/secure1.png" alt="" />
            </div>
            <div class="content">
              <div class="title">手机绑定</div>
            </div>
            <div class="content" style="position:absolute;right:45px;width: fit-content">
              <p class="text2" v-if="userInfo.gptsUserPhone">
               <span class="red">{{ userInfo.gptsUserPhone }} </span
               >
              </p>
              <p class="text1" v-else>未绑定</p>
            </div>

            <div class="itemLogo" >
            </div>
          </div>
          <div @click="toEmail1()" :class="[!userInfo.gptsUserEmail ? 'error' : '', 'item']">
            <div class="icon">
              <img loading="lazy" src="@/assets/secure2.png" alt="" />
            </div>
            <div class="content">
              <div class="title">邮箱绑定</div>
            </div>
            <div class="content" style="position:absolute;right:45px;width: fit-content">
              <p class="text2" v-if="userInfo.gptsUserEmail">
                <span class="red">{{ userInfo.gptsUserEmail }}</span
                >
              </p>
              <p class="text1" v-else>未绑定</p>
            </div>

            <div class="itemLogo" >
            </div>
          </div>
          <div @click="toPassword1()" class="item">
            <div class="icon">
              <img loading="lazy" src="@/assets/secure3.png" alt="" />
            </div>
            <div class="content">
              <div class="title">密码设置</div>
              <p class="text1">
              </p>
            </div>

            <div class="itemLogo" >
            </div>
          </div>
          <div v-if="isBind" @click="toWeixin()" class="item">
            <div class="icon">
              <img loading="lazy" src="@/assets/secure4.png" alt="" />
            </div>
            <div class="content">
              <div class="title">微信解绑</div>
            </div>
            <div class="content" style="position:absolute;right:45px;width: fit-content">
              <p class="text2" v-if="isBind">已绑定</p>
              <p class="text1" v-else>
                未绑定
              </p>
            </div>
            <div class="itemLogo" >
            </div>
          </div>
          <div v-else class="item">
            <div class="icon">
              <img loading="lazy" src="@/assets/secure4.png" alt="" />
            </div>
            <div class="content">
              <div class="title">微信解绑</div>
            </div>
            <div class="content" style="position:absolute;right:45px;width: fit-content">
              <p class="text2" v-if="isBind">已绑定</p>
              <p class="text1" v-else @click="login">
                未绑定，请前往登录页面绑定
              </p>
            </div>
            <div class="itemLogo" >
            </div>
          </div>
        </div>
        <el-dialog
            title="温馨提示"
            :visible.sync="dialogVisible"
            width="75%"
            :before-close="handleClose1"
            top="40vh"
        >
          <span>是否确认解除绑定，解除绑定后，无法通过微信进行登录</span>
          <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose1"> 取消 </el-button>
            <el-button type="primary" @click="handTo"> 确认 </el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import {unbindAccount, social, getAuthorize} from "@/api/user";
import { shareUrl } from "@/utils/vxshare.js";

export default {
  name: "Secure",
  components: {},

  data() {
    return {
      changeVisable:1,
      isMobile: false,
      baseURL: this.$utils.baseURL,
      userInfo: localStorage.getItem("userInfo"),
      dialogVisible: false,
      languageSelect: "",
      isBind: false,
    };
  },
  computed: {},
  watch: {
    "$store.state.user"(newValue) {
      this.userInfo = newValue;
    },
  },
  created() {
    this.userInfo=JSON.parse(this.userInfo)
    localStorage.setItem("myclick", 1);
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    this.social();
    let url = window.location.host;
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        title: "油气知识",
        text: "洞悉油气行业变化，体验AI大模型，就来油气知识！",
      };
      shareUrl(shareData);
    }, 1000);
  },
  mounted() {
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  },
  methods: {
    changeheadVisable(){
      if(this.changeVisable===1){
        this.changeVisable=0
      }
      else{
        this.changeVisable=1
      }
      console.log("父组件", this.changeVisable)
    },
    slip() {
      this.$router.go(-1);
    },
    toPhone() {
      if (this.userInfo.phonenumber) {
        this.$router.push({ path: "/changePhone", query: { type: 1 } });
      } else {
        this.$router.push("/changePhone");
      }
    },
    toEmail() {
      if (this.userInfo.phonenumber) {
        this.$router.push({ path: "/changeEmail", query: { type: 1 } });
      } else {
        this.$router.push("/changeEmail");
      }
    },
    toPassword() {
      this.$router.push("/changePassword");
    },
    toWeixin() {
      this.dialogVisible = true;
    },
    social() {
      let data = {
        language: "zh",
        access_token: localStorage.getItem("Zyaccess_token"),
      };
      social(data).then((res) => {
        console.log(res);
        if (res.data.isBind) {
          this.isBind = res.data.isBind;
          this.socialUid = res.data.socialUid;
        } else {
          this.isBind = false;
          // if (this.languageSelect == "zh") {
          //   this.$message.error("您暂未进行微信绑定 请前往登录页面进行绑定");
          // } else {
          //   this.$message.error(
          //     "You have not yet bound to WeChat. Please go to the login page to bind"
          //   );
          // }
        }
      });
    },
    handTo() {
      let data = {
        access_token: localStorage.getItem("Zyaccess_token"),
        socialUid: this.socialUid,
      };
      unbindAccount(data).then(() => {
        this.dialogVisible = false;
        this.socialUid = null;
        if (this.languageSelect == "zh") {
          this.$message({
            message: "解绑成功!", //提示的信息
            type: "success", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              //跳转页面或执行方法
              location.reload();
            },
          });
        } else {
          this.$message({
            message: "Unbind successful!", //提示的信息
            type: "success", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              //跳转页面或执行方法
              location.reload();
            },
          });
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    toPhone1() {
      if (this.userInfo.phonenumber) {
        this.$router.push({ path: "/changePhone1", query: { type: 1 } });
      } else {
        this.$router.push("/changePhone1");
      }
    },
    toEmail1() {
      if (this.userInfo.phonenumber) {
        this.$router.push({ path: "/changeEmail1", query: { type: 1 } });
      } else {
        this.$router.push("/changeEmail1");
      }
    },
    toPassword1() {
      this.$router.push("/changePassword1");
    },
    login() {
      // this.$router.push("/login")
      // let href = "https://www.oilgasgpts.com/";
      let href = window.location.href.split("&code")[0];
      getAuthorize(href).then((res) => {
        window.location.href = res.data.url;
      });
    },
    handleClose1() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style scoped lang="scss">
.box1 {
  //background: #fff;
  width: 100vw;
  margin: 5px auto;
  .ViewTitle {
    height: 45px;
    line-height: 45px;
    border-bottom: 1px solid #ebebeb;
    font-size: 16px;
    color: #333333;
    font-weight: 400;
    padding: 0 50px;
    text-align: center;
  }

  .list {
    padding: 0 10px;
    .item {
      margin: 8px auto;
      border-radius: 8px;
      background: #fff;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .itemLogo{
        background-image: url("../../assets/arrows.png");
        width: 15px;
        margin-right: 10px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        height: 15px;
        position: absolute;
        right: 15px;
        color: #6e6e6e;
        font-size: 25px;
      }
      .icon {
        width: 28px;
        height: 28px;
        margin-right: 13px;
      }
      .content {
        flex: 1;
        width: 0;
        .title {
          font-size: 14px;
          color: #222222;
        }
        p {
          font-size: 12px;
          color: #333333;
        }
      }
      .button {
        margin-left: 30px;
        width: 78px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border: 1px solid #ebebeb;
        color: #333333;
        font-size: 14px;
        cursor: pointer;
      }
    }
    .item:not(:last-child) {
      border-bottom: 1px solid #ebebeb;
    }
  }
}
::v-deep {
  .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
    .el-dialog__title {
      font-weight: bold;
      font-size: 16px;
    }
  }
  .el-button {
    width: 100px;
    height: 28px;
    line-height: 28px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    border: 1px solid var(--main-color);
    color: var(--main-color);
    padding: 0;
  }
  .el-button--primary {
    border: 1px solid var(--main-color);
    background: var(--main-color);
    color: #fff;
  }
  .el-button:focus,
  .el-button:hover {
    color: var(--main-color);
    border-color: var(--main-color);
    background-color: #fff;
  }
  .el-button--primary:focus,
  .el-button--primary:hover {
    background: var(--main-color);
    border-color: var(--main-color);
    color: #fff;
  }
  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: var(--main-color);border:1px solid var(--main-color);
  }
}
.red {
  //color: gray;
}
</style>

